<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="12">
        <h1>{{ $auth.account.name }} B2B Customer Portal</h1>
        <img v-if="$auth.account.banner_url" :src="$auth.account.banner_url" class="banner">
      </v-col>
    </v-row>

    <v-alert
      text
      outlined
      border="left"
      type="info"
      color="primary"
      dense
    >
      <h3>havebike Contact Details</h3>
      <p>If you are having issues booking bicycles in for service, or have any questions - please contact havebike on:</p>
      <p>Tel: <strong>0333 050 9288</strong> or email: <strong>{{ $auth.account.contact_email || 'sarah.hibbert@havebike.co.uk' }}</strong></p>
    </v-alert>

    <v-row>
      <v-col
        :lg="4"
        :md="3"
        :cols="12"
      >
        <v-card tile>
          <v-list shaped>
            <v-subheader>QUICK ACTIONS</v-subheader>
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in items"
                v-user-can="item.permission"
                :key="i"
                :to="item.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        :lg="8"
        :md="9"
        :cols="12"
      >
        <v-card tile v-user-can:LIST_ACCOUNT_JOBS>
          <v-card-text class="pt-4">
            <h2>Service Jobs</h2>
            <DataTable
              ref="dataTable"
              :show-select="false"
              :cols="cols"
              :path="`accounts/${$auth.account.id}/jobs`"
              :request-params="{ with: ['asset.servicePlans', 'booking.customer', 'service'] }"
              :options="{ itemsPerPage: 5 }"
              default-order-by="created_at"
            >
              <template v-slot:item.asset="{ item: job }">
                <router-link
                  v-if="job.asset && $auth.userCan('READ_ASSETS')"
                  :to="{ name: 'assets.show', params: { assetId: job.asset.id } }"
                >
                  {{ job.asset ? job.asset.number : '-' }}
                </router-link>
                <span v-else-if="job.asset">{{ job.asset ? job.asset.number : '-' }}</span>
                <span v-else>-</span>
              </template>
              <template v-slot:item.booker="{ item: job }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    {{ job.booking.customer.name }}
                    <v-icon
                      v-on="on"
                      color="grey"
                      small
                      dense
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ job.booking.customer_email }} | {{ job.booking.customer_mobile }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.service="{ item: job }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    {{ job.service.name }}
                    <v-icon
                      v-on="on"
                      color="grey"
                      small
                      dense
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ job.service.description }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.service_plans="{ item: job }">
                <div>
                  <template v-user-can:LIST_ACCOUNT_SERVICE_PLANS>
                    <div v-if="job.asset">
                      <v-chip
                        v-for="plan in job.asset.service_plans"
                        :key="plan.pivot.id"
                        small
                      >
                        {{ plan.name }}
                      </v-chip>
                    </div>
                    <div v-else>-</div>
                  </template>
                  <span v-user-cant:LIST_ACCOUNT_SERVICE_PLANS>-</span>
                </div>
              </template>
              <template v-slot:item.collection_date="{ item: job }">
                {{ $momentDate(job.booking.collection_date) }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="grey"
                      small
                      dense
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ job.booking.collection_address }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.return_date="{ item: job }">
                {{ $momentDate(job.booking.return_date) }}
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      color="grey"
                      small
                      dense
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ job.booking.return_address }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.rearranged_collection_date="{ item: job }">
                {{ job.rearranged_collection_date ? $momentDate(job.rearranged_collection_date) : '-' }}
              </template>
              <template v-slot:item.rearranged_return_date="{ item: job }">
                {{ job.rearranged_return_date ? $momentDate(job.rearranged_return_date) : '-' }}
              </template>
            </DataTable>
          </v-card-text>
        </v-card>
        <v-card tile v-user-can="'LIST_ACCOUNT_ASSETS'">
          <v-card-text class="pt-4 mt-4">
            <h2>Assets</h2>
            <DataTable
              ref="dataTable"
              :show-select="false"
              :cols="assetCols"
              :request-params="{ with: ['servicePlans', 'assetable', 'lastService'] }"
              :path="`accounts/${$auth.account.id}/assets`"
              default-order-by="created_at"
            >
              <template v-slot:item.number="{ item: asset }">
                <router-link v-if="$auth.userCan('READ_ASSETS')" :to="{ name: 'assets.show', params: { assetId: asset.id } }">
                  {{ asset.number || 'N/A' }}
                </router-link>
                <span v-else>{{ asset.number || 'N/A' }}</span>
              </template>
              <template v-slot:item.assetable="{ item: asset }">
                <v-chip
                  small
                >
                  {{ asset.assetable.name }}                  
                </v-chip>
              </template>
              <template v-slot:item.service_plans="{ item: asset }">
                <div>
                  <template v-user-can:LIST_ACCOUNT_SERVICE_PLANS>
                    <v-chip
                      v-for="plan in asset.service_plans"
                      :key="plan.pivot.id"
                      small
                    >
                      {{ plan.name }}
                    </v-chip>
                  </template>
                  <span v-user-cant:LIST_ACCOUNT_SERVICE_PLANS>-</span>
                </div>
              </template>
              <template v-slot:item.last_service_date="{ item: asset }">
                {{ asset.last_service ? $momentDate(asset.last_service.created_at) : '-' }}
              </template>
            </DataTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable'

export default {
  name: 'Dashboard',

  components: {
    DataTable
  },

  data: () => ({
    items: [
      { text: 'New Booking', permission: 'CREATE_BOOKINGS', icon: 'mdi-progress-wrench', to: { name: 'bookings.new' } },
      { text: 'New User', permission: 'INVITE_USERS', icon: 'mdi-account-plus', to: { name: 'users.new' } },
      { text: 'New Asset', permission: 'CREATE_ASSETS', icon: 'mdi-clipboard-plus', to: { name: 'assets.new' } },
      { text: 'Get Reports', permission: 'LIST_ACCOUNT_REPORTS', icon: 'mdi-file-chart', to: { name: 'reports' } },
    ],
  }),

  computed: {
    assetCols () {
      var cols = [
        { text: 'Asset Number', value: 'number' },
        { text: 'Account', value: 'assetable' },
        { text: 'Frame Number', value: 'frame_number' },
        { text: 'Manufacturer', value: 'manafacturer' },
        { text: 'Model', value: 'model' }
      ]
      if (this.$auth.userCan('LIST_ACCOUNT_SERVICE_PLANS')) {
        cols.push({ text: 'Service Plans', value: 'service_plans' })
      }
      cols.push({ text: 'Last Service Date', value: 'last_service_date' })
      return cols
    },

    cols () {
      var cols = [
        { text: 'Job Number', value: 'ref' },
        { text: 'Booker', value: 'booker' },
        { text: 'Service', value: 'service' },
        { text: 'Job Type', value: 'job_type_label' },
        { text: 'Collection Status', value: 'collection_status_label' },
        { text: 'Workshop Status', value: 'workshop_status_label' },
        { text: 'Return Status', value: 'return_status_label' },
        { text: 'Asset', value: 'asset' },
        { text: 'Collection', value: 'collection_date' },
        { text: 'Return', value: 'return_date' },
        { text: 'Rescheduled Collection', value: 'rearranged_collection_date' },
        { text: 'Rescheduled Return', value: 'rearranged_return_date' },
      ]
      if (this.$auth.userCan('LIST_ACCOUNT_SERVICE_PLANS')) {
        cols.push({ text: 'Service Plans', value: 'service_plans' })
      }
      return cols
    }
  }
}
</script>
<style type="text/css">
  .banner {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: left;
  }
</style>
